<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div
        class="d-flex flex-row align-center">
        <v-icon class="mr-3">mdi-folder</v-icon> POs
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <v-text-field
        class="mt-3"
        style="max-width: 200px"
        append-icon="mdi-magnify"
        @click:append="fetchPos"
        @keyup.enter="fetchPos"
        label="Buscar"
        v-model="search"
        solo
        outlined
        flat
        dense
        hide-details
        ></v-text-field>

      <v-card
        class="mt-3"
        outlined
        v-if="!pos.length">
        <v-card-text>
          Aún no tiene archivos en este directorio.
        </v-card-text>
      </v-card>

      <div
        v-else
        >
        <v-card
          class="my-3"
          outlined
          v-for="(file, idx) in list"
          @click="redirect(file.fileUrl)"
          >
          <v-card-text
            class="pa-2">
            <v-row
              class="d-flex flex-row">
              <v-col
                class="shrink">
                <v-card
                  height="70"
                  width="70"
                  color="grey lighten-2"
                  >
                  <v-card-text
                    class="fill-height d-flex align-center justify-center"
                    >
                    .csv
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                class="grow align-self-center">
                {{ file.number }}
                <br>
                {{ (file.createdAt).toLocaleString() }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <v-card
        v-if="totalPages"
        width="400"
        class="mx-auto"
        flat
        >
        <v-card-text>
          <v-pagination
            v-model="page"
            :length="totalPages"
            ></v-pagination>
        </v-card-text>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { PoList } from '@/graphql/queries/pos'

export default {
  data: () => ({
    pos: [],
    page: 1,
    search: null,
  }),

  created () {
    this.fetchPos ()
  },

  props: {
    siteId: {
      type: String | Number,
      required: true
    }
  },

  computed: {
    url () {
      return process.env.VUE_APP_RAILS_URL
    },

    list () {
      return this.pos.slice( (this.page - 1) * 7, this.page * 7)
    },

    totalPages () {
      if (!this.pos || this.pos.length < 8) return 0
      return this.pos.length / 7
    }
  },

  methods: {
    fetchPos () {
      this.$apollo.query({
        query: PoList,
        variables: {
          id: this.$route.params.id,
          search: this.search,
          siteId: this.siteId
        }
      }).then ( res => {
        this.pos = res.data.poList.pos
      })
    },

    redirect (url) {
      window.open(`${this.url + url}`, '_blank')
    }
  }
}
</script>
