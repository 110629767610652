import gql from 'graphql-tag'

export const Folders = gql`
  query folders($projectId: ID!, $siteId: ID) {
    folders(projectId: $projectId, siteId: $siteId) {
      id
      name
    }
  }
`

export const Files = gql`
  query files($folderId: ID!, $siteId: ID) {
    files(folderId: $folderId, siteId: $siteId) {
      id
      fileUrl
      createdAt
      filename
      filesize
    }
  }
`
