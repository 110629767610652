import gql from 'graphql-tag'

export const Pos = gql`
  query pos($rolloutId: ID!) {
    pos(rolloutId: $rolloutId) {
      id
      number
      supplierName
      fileUrl
      billedAt
      docDate
      deliveryAddress
      totalValue
      freeText
      checked
      idoc
      billNumber
      approvalCode
      amount
      closed
      lines {
        id
        quantity
        pciDescription 
        description
        unit
        netPrice
        totalPrice
        checked
        technology
      }
    }
  }
`

export const PoList = gql`
  query poList($id: ID!, $search: String, $siteId: ID) {
    poList(id: $id, search: $search, siteId: $siteId) {
      pos {
        id
        number
        fileUrl
        createdAt
      }
      totalPages
    }
  }
`

export const HuaweiPos = gql`
  query huaweiPos($rolloutId: ID!) {
    huaweiPos(rolloutId: $rolloutId) {
      id
      fileUrl
      createdAt
      billedAt
      billNumber
      esar
      approvalCode
      checked
      closed
      amount

      projectCode
      siteCode
      siteName
      siteId
      poNumber
      lineNumber
      itemCode
      unitPrice
      itemDescription
      requestedQuantity
      totalValue

      link {
        id
      }
    }
  }
`
