<template>
  <div>
    <v-expansion-panels 
      v-model="panel"
      focusable
      v-if="siteId"
      :key="siteId"
      class="rounded-lg"
      dense
      >
      <pos
        :siteId="siteId"
        ></pos>

      <template
        v-for="(folder, i) in folders"
        >
        <folder
          :key="i"
          :folder="folder"
          :siteId="siteId"
          ></folder>
      </template>
    </v-expansion-panels>

    <v-alert
      v-else
      type="info"
      class="rounded-lg"
      >
      Busque un sitio para continuar.
    </v-alert>
  </div>
</template>

<script>
import { Folders } from '@/graphql/queries/repository'

import Pos from './Pos'
import Folder from './Folder'

export default {
  data () {
    return {
      panel: null,
      folders: [],
    }
  },

  props: {
    siteId: {
      type: String | Number,
      required: true
    }
  },

  created () {
    this.fetchFolders ()
  },

  methods: {
    fetchFolders () {
      this.$apollo.query({
        query: Folders,
        variables: {
          projectId: this.$route.params.id,
          siteId: this.siteId
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.folders = res.data.folders
      })
    },
  },

  components: {
    Pos,
    Folder,
  }
}
</script>
