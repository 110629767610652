<template>
  <v-expansion-panel
    >
    <v-expansion-panel-header>
      <div
        class="d-flex flex-row align-center">
        <v-icon class="mr-3">mdi-folder</v-icon> {{ folder.name }}
        <v-spacer></v-spacer>

        <create-file
          ref="createFile"
          :folderId="folder.id"
          :siteId="siteId"
          :sites="sites"
          @reload="fetchFiles"
          ></create-file>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card
        class="mt-3"
        outlined
        v-if="!files.length">
        <v-card-text>
          Aún no tiene archivos en este directorio. Haga click <a @click="$refs.createFile.dialog = true" class="font-weight-bold">aquí</a> para añadirlos.
        </v-card-text>
      </v-card>
      <div
        v-else
        >
        <v-card
          class="my-3"
          outlined
          v-for="(file, idx) in files"
          @click="redirect(file.fileUrl)"
          >
          <v-card-text
            class="pa-2">
            <v-row
              class="d-flex flex-row">
              <v-col
                class="shrink">
                <v-card
                  height="70"
                  width="70"
                  color="grey lighten-2"
                  >
                  <v-card-text
                    class="fill-height d-flex align-center justify-center"
                    >
                    .{{ file.filename.split('.')[1] }}
                  </v-card-text>
                </v-card>
                </v-img>
              </v-col>

              <v-col
                class="grow align-self-center">
                {{ file.filename }}
                <br>
                {{ file.filesize }}
                <br>
                {{ (file.createdAt).toLocaleString() }}
              </v-col>

              <v-col
                class="py-0 d-flex flex-row shrink align-self-center">
                <delete-file
                  :file="file"
                  @reload="fetchFiles"
                  ></delete-file>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { Files } from '@/graphql/queries/repository'

const CreateFile = () => import('./CreateFile')
const DeleteFile = () => import('./DeleteFile')

export default {
  data: () => ({
    files: [],
  }),

  props: {
    folder: {
      type: Object,
      required: true
    },

    siteId: {
      type: String | Number,
      required: true,
    },

    sites: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },

  watch: {
    siteId () {
      this.fetchFiles ()
    }
  },

  computed: {
    url () {
      return process.env.VUE_APP_RAILS_URL
    },
  },

  created () {
    this.fetchFiles ()
  },

  methods: {
    fetchFiles () {
      this.$apollo.query({
        query: Files,
        variables: {
          folderId: this.folder.id,
          siteId: this.siteId
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.files = res.data.files
      })
    },

    redirect (url) {
      window.open(`${this.url + url}`, '_blank')
    }
  },

  components: {
    CreateFile,
    DeleteFile
  }
}
</script>
